
<template>
  <div>
    <div primary-title class="pb-3">
      <div 
        :class="['font-weight-medium blue-grey--text text--darken-4', $vuetify.breakpoint.smAndUp ? 'text-body-1' : 'text-body-2']" 
        style="word-break: break-word"
      >{{course.name}}</div>
    </div>
    <div>
      <div class="mb-0">
        <v-chip :small="!$vuetify.breakpoint.smAndUp" :pill="$vuetify.breakpoint.smAndUp" class="pl-0" color="grey lighten-3"><v-avatar color="purple" class="mr-1">
          <v-icon :small="!$vuetify.breakpoint.smAndUp" color="white">mdi-account-tie</v-icon></v-avatar>{{comp_coord_name}}
        </v-chip>
      </div>
      <div>
        <div :class="['mb-2 pa-auto mt-2', $vuetify.breakpoint.smAndUp ? 'd-flex' : '' ]" v-if="!is_component_use_in_student_access">
          <!-- <v-chip :small="!$vuetify.breakpoint.smAndUp" class="mt-2" label color="transparent">
            <v-icon :small="!$vuetify.breakpoint.smAndUp" left size="16">mdi-account-plus</v-icon> 
            <span class="font-weight-medium" left> Fim das incrições </span>
          </v-chip> -->
          <div class="d-flex align-center">
            <v-icon :small="!$vuetify.breakpoint.smAndUp" left size="16">mdi-account-clock</v-icon> 
            <span class="font-weight-medium "> Fim das incrições: </span> 
          </div>
          <v-chip :small="!$vuetify.breakpoint.smAndUp" class="ml-1" label color="grey lighten-3">
            <span :class="$dayjs(course.register_end_date).endOf('day').isBefore($dayjs().startOf('day')) ? 'red--text' : ''">
              {{$dayjs(course.register_end_date).format('DD/MM/YYYY')}}
            </span> 
          </v-chip>
        </div>
        <div class="d-flex align-center pb-1">
          <v-icon :small="!$vuetify.breakpoint.smAndUp" left size="16">mdi-calendar-account</v-icon> 
          <span class="font-weight-medium "> Data do curso: </span> 
        </div>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-chip :small="!$vuetify.breakpoint.smAndUp" class="mr-2 mb-2" color="grey lighten-3" v-bind="attrs" v-on="on">
              <v-icon size="18" color="green" left>mdi-calendar</v-icon>{{$dayjs(course.start_date).format('DD/MM/YYYY')}}
            </v-chip>
          </template>
          <span>Início</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }"> 
            <v-chip :small="!$vuetify.breakpoint.smAndUp" class="mr-2 mb-2" color="grey lighten-3" v-bind="attrs" v-on="on">
              <v-icon size="18" color="red lighten-1" left>mdi-calendar</v-icon>{{$dayjs(course.end_date).format('DD/MM/YYYY')}}
            </v-chip>
          </template>
          <span>Término</span>
        </v-tooltip>
      </div>
      <div>
        <v-chip :small="!$vuetify.breakpoint.smAndUp" class="mt-2" :color="course.kind == 'in_person' ? 'pink' : 'success'" label text-color="white">
          <v-icon left>mdi-label</v-icon>{{course.kind == 'distance' ? 'A distancia' : 'Presencial'}}
        </v-chip>
      </div>
      <div>
        <v-chip :small="!$vuetify.breakpoint.smAndUp" class="mt-2" :color="status_map[course.status.toString()].color" label text-color="white" v-if="!['student'].includes(user.role.name)" >
          <v-icon :small="!$vuetify.breakpoint.smAndUp" left>{{status_map[course.status.toString()].icon}}</v-icon>{{status_map[course.status.toString()].title}}
        </v-chip>
      </div>
    </div>
    <div>
      <div v-if="is_component_use_in_student_access && !is_student_list_waiting_selection">
        <enrollment-confirm-tip :course="course"/>
      </div>
      <v-btn color="primary" small class="mt-2"
        v-if="['super_admin', 'admin', 'director'].includes(user.role.name) && course.status == 'selecting'" 
        @click="selectionDone(course)"
        :disabled="aux_disabled" 
      ><v-icon> mdi-play</v-icon> Encerrar seleção</v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Api from '@/api/index'

export default {
  name: 'CourseListDataIteratorContent',
  props: {
    course: {
      type: Object,
      default: null
    },
    is_component_use_in_student_access: {
      type: Boolean
    },
    is_student_list_waiting_selection: {
      type: Boolean
    },
  },
  components: {
    EnrollmentConfirmTip: () => import ('./EnrollmentConfirmTip.vue'),
  },
  data() {
    return {
      status_map: {
        active: {title: 'Ativo' , color: 'primary', icon: 'mdi-bookmark-check'}, 
        selecting: {title: 'Seleção' , color: 'primary', icon: 'mdi-clipboard-clock-outline'}, 
        done_selection: {title: 'Seleção pronta'  , color: 'primary', icon: 'mdi-check-circle-outline' }, 
        in_progress: {title: 'Em andamento'  , color: 'primary', icon: 'mdi-progress-clock' }, 
        done: {title: 'Finalizado' , color: 'success', icon: 'mdi-check-all' }, 
        suspended: {title: 'Suspenso' , color: 'error', icon: 'mdi-alert-circle-outline'}
      },

      alert: false,
      aux_disabled: false,
    }
  },
  methods: {
    selectionDone(course_to_update){
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        title: 'Deseja realizar operação?',
        icon: 'question',
      }).then((r) => { 
        if(r.isConfirmed){
          let course = this.prepareCourseStatus(course_to_update, 'done_selection')
          this.updateCourse(course)
        }
      })
    },
    updateCourse(course){
      Api.Course.update(course).then(()=>{
        this.addNotification({type: 'success', msg: 'Realizado com sucesso'})
      }).catch(err => {
        this.addNotification({type: 'error', msg: `Erro: ${err}`})
      }).finally(() => {
        this.aux_disabled = true
      })
    },
    prepareCourseStatus(course_to_update, status){
      let course = {
        id: course_to_update.id,
        status: status
      }
      return course
    },
    
    ...mapActions({
      addNotification: 'Notification/add'
    })
  },
  computed: {
    comp_coord_name(){
      let name = this.course.coordinator_name.split(/[,;-]/)
      if (name.length > 1){
        return name[0] //+ ' & ...'
      }
      return name[0]
    },
    ...mapState({
      user: state=> state.User.user
    })
  },

}
</script>

<style scoped>

</style>